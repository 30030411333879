import React from 'react';
import io from 'socket.io-client';
import { makeStyles } from '@material-ui/core/styles';
import { Route, Switch, Redirect } from 'react-router-dom';
import routes from '../routes';

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    width: '100%',
    padding: theme.spacing(3),
    overflowX: 'hidden',
  },
}));

function Content() {
  const socket = io(process.env.REACT_APP_API_URL);
  const classes = useStyles();

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Switch>
        {routes.map(({ component: Component, ...route }, key) => (
          <Route
            key={key}
            exact={route.exact}
            path={route.path}
            render={(props) => <Component {...{ ...props, socket }} />}
          />
        ))}
        {/*<Route component={NotFound}/>*/}
        <Redirect to="/" />
      </Switch>
    </main>
  );
}

export default Content;
