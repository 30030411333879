import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { axios, debounce } from '../helpers';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Block from '@material-ui/icons/Block';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import Box from '@material-ui/core/Box';

const columns = [
  { id: 'fullName', label: 'Full Name', align: 'left' },
  { id: 'email', label: 'Email', align: 'center' },
  { id: 'phoneNumber', label: 'Phone Number', align: 'center', minWidth: 150 },
  { id: 'address', label: 'Address', align: 'center' },
  { id: 'ordersCount', label: 'Orders count', align: 'center', minWidth: 150 },
  { id: 'createdAt', label: 'Date', align: 'right', minWidth: 150 },
  { id: 'blockUser', label: 'Block', align: 'right' },
];

const useStyles = makeStyles({
  addButtonRoot: {
    '& > *': {
      bottom: 0,
    },
  },
  filterButton: {
    marginLeft: 10,
  },
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 430,
  },
});

export default function Users(props) {
  const classes = useStyles();
  const urlSearchParams = new URLSearchParams(props.history.location.search);
  const { search: urlSearch } = Object.fromEntries(urlSearchParams.entries());
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [users, setUsers] = React.useState([]);
  const [usersTotal, setUsersTotal] = React.useState(0);
  const [filterQuery, setFilterQuery] = React.useState({ blocked: false });

  const getUsers = (page, rowsPerPage, search = urlSearch, query = {}) => {
    const params = {
      rowsPerPage,
      page,
      search,
      ...query,
    };
    return axios.get(`${process.env.REACT_APP_API_URL}/api/users`, { params });
  };
  React.useEffect(() => {
    getUsers(page, rowsPerPage, urlSearch, filterQuery).then((v) => {
      setUsers(v.data.result);
      setUsersTotal(v.data.total);
    });
  }, []);

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    const { data } = await getUsers(newPage, rowsPerPage);
    setUsers(data.result);
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    const { data } = await getUsers(0, event.target.value);
    setUsers(data.result);
  };

  const handleSearchUser = debounce((value) => {
    getUsers(null, null, value, filterQuery).then((v) => {
      props.history.push(`${props.location.pathname}?search=${value}`);
      setUsers(v.data.result);
      setUsersTotal(v.data.total);
    });
  }, 500);

  const handleBlockUser =
    ({ _id, blocked }) =>
    () => {
      axios
        .put(`${process.env.REACT_APP_API_URL}/api/users/${_id}/block`, {
          blocked: !blocked,
        })
        .then(() => {
          getUsers(null, null, urlSearch, filterQuery).then((v) => {
            setUsers(v.data.result);
            setUsersTotal(v.data.total);
          });
        });
    };

  const handleFilter = (blocked) => async () => {
    setFilterQuery((state) => ({ ...state, blocked }));
    const { data: users } = await getUsers(page, rowsPerPage, urlSearch, { blocked: blocked });
    setUsers(users.result);
    setUsersTotal(users.total);
  };
  console.log(filterQuery);

  return (
    <Paper className={classes.root}>
      <Box display="flex" justifyContent="space-betseen">
        <TextField
          onChange={(e) => handleSearchUser(e.target.value)}
          autoFocus
          size="small"
          defaultValue={urlSearch}
          fullWidth
          id="outlined-basic"
          label="Search By Address / Username / Full Name / Phone Number / Email"
          variant="outlined"
        />
        <Button
          className={classes.filterButton}
          onClick={handleFilter(!filterQuery.blocked)}
          variant="outlined"
          color={filterQuery.blocked ? 'secondary' : 'primary'}
        >
          {filterQuery.blocked ? <Block /> : <CheckCircleOutline />}
        </Button>
      </Box>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((row) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <TableCell key={column.id} align={column.align}>
                      {column.id === 'createdAt' ? (
                        value ? (
                          moment(value).format('MM-DD-YYYY, HH:mm')
                        ) : (
                          '-'
                        )
                      ) : column.id === 'blockUser' ? (
                        row.blocked ? (
                          <IconButton onClick={handleBlockUser(row)} color="secondary" title="Unblock">
                            <Block />
                          </IconButton>
                        ) : (
                          <IconButton onClick={handleBlockUser(row)} color="primary" title="Block">
                            <CheckCircleOutline />
                          </IconButton>
                        )
                      ) : (
                        value
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[30, 70, 150]}
        component="div"
        count={usersTotal}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
