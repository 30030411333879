export const EDIT_ADDITIVE_PENDING = 'EDIT_ADDITIVE_PENDING';
export const EDIT_ADDITIVE_SUCCESS = 'EDIT_ADDITIVE_SUCCESS';
export const EDIT_ADDITIVE_ERROR = 'EDIT_ADDITIVE_ERROR';

export function editAdditivePending(additive) {
  return {
    type: EDIT_ADDITIVE_PENDING,
    payload: additive,
  };
}

export function editAdditiveSuccess(response) {
  return {
    type: EDIT_ADDITIVE_SUCCESS,
    payload: response,
  };
}

export function editAdditiveError(error) {
  return {
    type: EDIT_ADDITIVE_ERROR,
    payload: error,
  };
}
