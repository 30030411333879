import { axios } from '../../helpers';
import { call, put, takeLatest } from 'redux-saga/effects';
import { EDIT_ITEM_PENDING, editItemSuccess, editItemError } from '../../actions';

export function* editItem() {
  yield takeLatest(EDIT_ITEM_PENDING, function* ({ payload }) {
    try {
      const response = yield call(axios.put, `api/items/${payload.itemId}`, payload.formData);
      yield put(editItemSuccess(response.data));
    } catch (e) {
      yield put(editItemError(e.response.data.message));
    }
  });
}
