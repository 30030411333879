import { FETCH_CATEGORIES_PENDING, FETCH_CATEGORIES_SUCCESS, FETCH_CATEGORIES_ERROR } from '../../actions';

const initialState = {
  categories: [],
  success: null,
  error: false,
};

export const fetchCategoriesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CATEGORIES_PENDING:
      return {
        ...state,
        success: false,
      };

    case FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: payload,
        success: true,
      };

    case FETCH_CATEGORIES_ERROR:
      return {
        ...state,
        success: false,
        error: true,
      };
    // case 'ADD_CATEGORIES':
    //     return {
    //         ...state,
    //         addCategorySuccess: false
    //     };

    // case 'ADD_CATEGORIES_SUCCESS':
    //     return {
    //         ...state,
    //         addCategorySuccess: true
    //     };

    // case 'ADD_CATEGORIES_ERROR':
    //     return {
    //         ...state,
    //         addCategorySuccess: null
    //     };
    default:
      return state;
  }
};
