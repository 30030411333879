import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { axios, beautyAddress, debounce } from '../helpers';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import { Collapse, IconButton, Typography } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const columns = [
  { id: 'orderId', label: '#', align: 'center' },
  { id: 'totalPrice', label: 'Total Price', align: 'center', minWidth: 150 },
  { id: 'deliveryPrice', label: 'Delivery', align: 'center' },
  { id: 'phoneNumber', label: 'Phone Number', align: 'center', minWidth: 150 },
  {
    id: 'takeAway',
    label: 'Take Away',
    align: 'center',
    minWidth: 150,
    render(takeAway) {
      return takeAway ? '📦' : '🚚';
    },
  },
  {
    id: 'address',
    label: 'Address',
    align: 'center',
    minWidth: 200,
    render(address, row) {
      return (
        <Box>
          {address}
          <br />
          <Typography variant="body2">{beautyAddress(row)}</Typography>
        </Box>
      );
    },
  },
  {
    id: 'payment',
    label: 'Payment',
    align: 'center',
    render(payment) {
      return payment?.paymentMethod ? mapping[payment.paymentMethod] : '';
    },
  },
  {
    id: 'payment.status',
    label: 'Payment Status',
    align: 'center',
    minWidth: 200,
    render(payment, row) {
      return row?.payment?.status ? mapping[row.payment.status] : '';
    },
  },
  { id: 'createdAt', label: 'Date', align: 'center', minWidth: 150 },
];

const useStyles = makeStyles({
  addButtonRoot: {
    '& > *': {
      bottom: 0,
    },
  },
  filterButton: {
    marginLeft: 10,
  },
  root: {
    width: '100%',
  },
  container: {
    height: 430,
  },
});

const mapping = {
  SUCCESS: 'Paid ✔️',
  PENDING: 'Unpaid ❌',
  CASH: 'Cash',
  IDRAM: 'Idram',
  TELCELL: 'Tell Cell',
  CREDIT_CARD: 'Credit Card',
  INECO_QR: 'Ineco',
  TELCELL_QR: 'Tell Cell QR',
};

export default function Orders(props) {
  const classes = useStyles();
  const urlSearchParams = new URLSearchParams(props.history.location.search);
  const { search: urlSearch } = Object.fromEntries(urlSearchParams.entries());
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [orders, setOrders] = React.useState([]);
  const [ordersTotal, setOrdersTotal] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const getOrders = useCallback(
    (page, rowsPerPage, search = urlSearch, query = {}) => {
      const params = {
        rowsPerPage,
        page,
        search: search || undefined,
        ...query,
      };
      return axios.get(`${process.env.REACT_APP_API_URL}/api/orders`, { params });
    },
    [urlSearch],
  );
  React.useEffect(() => {
    setLoading(true);
    getOrders(page, rowsPerPage, urlSearch)
      .then((v) => {
        setOrders(v.data.result);
        setOrdersTotal(v.data.total);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [getOrders, page, rowsPerPage, urlSearch]);

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    const { data } = await getOrders(0, event.target.value);
    setOrders(data.result);
  };

  const handleSearchOrder = debounce((value) => {
    setPage(0);
    props.history.push(`${props.location.pathname}?search=${value}`, {});
  }, 500);

  return (
    <Paper className={classes.root} style={loading ? { pointerEvents: 'none', opacity: 0.4 } : {}}>
      <Box style={{ backgroundColor: '#fafafa' }}>
        <TextField
          onChange={(e) => handleSearchOrder(e.target.value)}
          autoFocus
          size="small"
          defaultValue={urlSearch}
          id="outlined-basic"
          label="Search"
          variant="outlined"
        />
      </Box>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell />
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((row) => (
              <Row key={row.orderId} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[30, 70, 150]}
        component="div"
        count={ordersTotal}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {columns.map((column) => {
          const value = row[column.id];
          return (
            <TableCell key={column.id} align={column.align}>
              {column.id === 'createdAt'
                ? value
                  ? moment(value).format('MM-DD-YYYY, HH:mm')
                  : '-'
                : column?.render?.(value, row) || value}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length + 1}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Products
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Image</TableCell>
                    <TableCell align="center">Name</TableCell>
                    <TableCell align="center">Price</TableCell>
                    <TableCell align="center">Qty.</TableCell>
                    <TableCell align="center">Total Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.orderItems.map((orderItem) => (
                    <TableRow key={orderItem._id}>
                      <TableCell align="center" component="th" scope="row">
                        <img
                          width={50}
                          height={50}
                          className={classes.img}
                          src={`${process.env.REACT_APP_API_URL}/uploads/${orderItem.item.image}`}
                          alt={orderItem.item.itemName}
                        />
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {orderItem.item.itemName}
                      </TableCell>
                      <TableCell align="center">{orderItem.item.price}</TableCell>
                      <TableCell align="center">{orderItem.quantity}</TableCell>
                      <TableCell align="center">
                        {Math.round(orderItem.item.price * orderItem.quantity * 100) / 100}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
