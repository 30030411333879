export const PRINT_ORDER_PENDING = 'PRINT_ORDER_PENDING';
export const PRINT_ORDER_SUCCESS = 'PRINT_ORDER_SUCCESS';
export const PRINT_ORDER_ERROR = 'PRINT_ORDER_ERROR';

export function printOrderPending(payload) {
  return {
    type: PRINT_ORDER_PENDING,
    payload,
  };
}

export function printOrderSuccess(payload) {
  return {
    type: PRINT_ORDER_SUCCESS,
    payload,
  };
}

export function printOrderError(payload) {
  return {
    type: PRINT_ORDER_ERROR,
    payload,
  };
}
