import { axios } from '../helpers';
import { call, put, takeLatest } from 'redux-saga/effects';

function* getMe() {
  try {
    const response = yield call(axios.get, 'api/me');
    yield put({ type: 'GET_ME_SUCCESS', payload: response.data });
  } catch (error) {
    yield put({ type: 'GET_ME_ERROR', payload: error });
  }
}

export function* fetchMe() {
  yield takeLatest('GET_ME', getMe);
}
