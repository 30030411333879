import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { fetchAdditivesPending, editAdditivePending, addAdditivePending } from '../actions';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  img: {
    width: 200,
    height: '100%',
  },
  input: {
    display: 'none',
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class AdditiveModel {
  constructor() {
    this.additiveName = '';
  }
}

const AdditiveDialog = (props) => {
  const {
    open,
    handleClose,
    addAdditive,
    addAdditiveReducer,
    additive,
    getAdditives,
    editAdditive,
    editAdditiveReducer,
  } = props;
  const didMountRef = useRef(false);

  const classes = useStyles();
  const [state, setState] = useState({
    ...new AdditiveModel(),
  });

  const setInputValue = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const closeDialog = () => {
    const additive = new AdditiveModel();

    setState({ ...state, ...additive });
    handleClose();
  };

  const setInputValues = () => {
    setState({ ...state, ...additive });
  };

  useEffect(() => {
    if (didMountRef.current) {
      if (addAdditiveReducer.success) {
        closeDialog();
      }
      if (additive._id && !additive.edit) {
        setInputValues();
        additive.edit = true;
      }

      if (editAdditiveReducer.success) {
        closeDialog();
        getAdditives();
        editAdditiveReducer.success = null;
      }
    } else {
      didMountRef.current = true;
    }
  });

  const handleSave = async () => {
    const additiveModel = new AdditiveModel();

    for (const key in additiveModel) {
      additiveModel[key] = state[key];
    }

    if (additive._id) {
      editAdditive({ additiveModel, additiveId: additive._id });
    } else {
      addAdditive(additiveModel);
    }
  };

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {additive._id ? 'Edit additive' : 'Add additive'}
          </Typography>
          <Button autoFocus color="inherit" onClick={handleSave}>
            {additive._id ? 'save' : 'add'}
          </Button>
        </Toolbar>
      </AppBar>
      <form className={classes.container}>
        <Container fixed>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={6}>
              <TextField
                id="outlined-helperText"
                label="Name"
                name="additiveName"
                value={state.additiveName}
                className={classes.textField}
                margin="dense"
                variant="outlined"
                onChange={setInputValue}
              />
            </Grid>
          </Grid>
        </Container>
      </form>
    </Dialog>
  );
};

const mapStateToProps = ({ addAdditiveReducer, editAdditiveReducer }) => ({
  addAdditiveReducer,
  editAdditiveReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getAdditives() {
    dispatch(fetchAdditivesPending());
  },
  editAdditive(additive) {
    dispatch(editAdditivePending(additive));
  },
  addAdditive(additive) {
    dispatch(addAdditivePending(additive));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditiveDialog);
