import { axios } from '../../helpers';
import { call, put, takeLatest } from 'redux-saga/effects';
import { FETCH_ADDITIVES_PENDING, fetchAdditivesSuccess, fetchAdditivesError } from '../../actions';

export function* fetchAdditives() {
  yield takeLatest(FETCH_ADDITIVES_PENDING, function* () {
    try {
      const response = yield call(axios.get, 'api/additives');
      yield put(fetchAdditivesSuccess(response.data));
    } catch (error) {
      yield put(fetchAdditivesError(error));
    }
  });
}
