import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  img: {
    width: '100%',
    height: '100%',
  },
}));

const CategoryItems = ({ image, name, url, price, description, type, children }) => {
  const classes = useStyles();
  const categoryItemContent = (
    <Paper className={`${classes.paper} category-item`}>
      {children}
      <div>
        <img className={classes.img} src={`${process.env.REACT_APP_API_URL}/uploads/${image}`} alt={name} />
      </div>
      <div className="category-item__name">{name}</div>
      {type === 'item' ? (
        <div>
          <div className="category-item__price">{price} AMD</div>
          <div>{description}</div>
        </div>
      ) : (
        ''
      )}
    </Paper>
  );
  return (
    <Grid item xs={12} sm={4} lg={3}>
      {type === 'item' ? categoryItemContent : <Link to={url}>{categoryItemContent}</Link>}
    </Grid>
  );
};

export default CategoryItems;
