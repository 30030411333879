export const EDIT_ITEM_PENDING = 'EDIT_ITEM_PENDING';
export const EDIT_ITEM_SUCCESS = 'EDIT_ITEM_SUCCESS';
export const EDIT_ITEM_ERROR = 'EDIT_ITEM_ERROR';

export function editItemPending(item) {
  return {
    type: EDIT_ITEM_PENDING,
    payload: item,
  };
}

export function editItemSuccess(response) {
  return {
    type: EDIT_ITEM_SUCCESS,
    payload: response,
  };
}

export function editItemError(error) {
  return {
    type: EDIT_ITEM_ERROR,
    payload: error,
  };
}
