import React, { useEffect, useState, useRef } from 'react';
import { axios, beautyAddress } from '../helpers';
import getPercent from '../helpers/getPercent';
import { connect } from 'react-redux';
import { printOrderPending } from '../actions';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Print from '@material-ui/icons/Print';
import { Link } from 'react-router-dom';
import Send from '@material-ui/icons/Send';
import { Button, CircularProgress } from '@material-ui/core';
import UserSendNotificationModal from '../components/UserSendNotificationModal';
import RefreshIcon from '@material-ui/icons/Refresh';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 500,
    marginBottom: theme.spacing(2),
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  rightText: {
    textAlign: 'right',
  },
  priceBlock: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const Dashboard = ({ socket, ...props }) => {
  const ordersRef = useRef([]);
  const [state, setState] = useState({ orders: [] });
  const getMe = () => axios.get('/api/me');

  const [loading, setLoading] = useState('initial');

  const connectSocket = () => {
    socket.on('connect', async () => {
      const me = await getMe();
      const reqId = `admin-${me.data.user.username}${me.data.user._id}`;
      socket.on(reqId, (order) => {
        order.isNew = true;
        const orderIndex = ordersRef.current.map((v) => v._id).indexOf(order._id);
        if (orderIndex > -1) {
          ordersRef.current[orderIndex] = order;
        } else {
          ordersRef.current.unshift(order);
        }
        setState({ ...state, orders: ordersRef.current });
      });
    });
  };

  const [modalOrder, setModalOrder] = useState(null);

  const printOrderManually = (orderId) => async () => {
    try {
      props.printOrderPending(orderId);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const getOrders = async () => {
      try {
        setLoading('loading');
        const date = moment();
        const fromDate = date.subtract(1, 'days').format();
        const { data: orders } = await axios.get('/api/orders', {
          params: { createdAt: { $gte: fromDate } },
        });
        setState({ ...state, orders: orders.result });
        ordersRef.current = orders.result;
        setLoading('loaded');
      } catch (e) {
        console.log(e);
        setLoading('error');
      }
    };

    getOrders();
    connectSocket();
    return () => {
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />

      <Container maxWidth="sm">
        {loading === 'loading' || loading === 'initial' ? (
          <Box
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            marginTop="100px"
            flexDirection="column"
          >
            <Typography>Please wait</Typography>

            <CircularProgress style={{ marginTop: 20 }} />
          </Box>
        ) : loading === 'error' ? (
          <Box
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            marginTop="100px"
            flexDirection="column"
          >
            <Typography>Oops! Something went wrong.</Typography>

            <Typography>Try refreshing the page. If you still have trouble, contact our support team.</Typography>

            <Button
              color="primary"
              style={{ marginTop: 20 }}
              variant="contained"
              endIcon={<RefreshIcon />}
              onClick={() => window.location.reload()}
            >
              Refresh the page
            </Button>
          </Box>
        ) : (
          state.orders.map((order) => (
            <Card key={order._id} className={classes.card}>
              <CardContent>
                <Box justifyContent="space-between" alignItems="center" display="flex">
                  <Box>
                    <IconButton color="inherit" onClick={printOrderManually(order._id)}>
                      <Print />
                    </IconButton>

                    <IconButton color="inherit" aria-label="delete" onClick={() => setModalOrder(order)}>
                      <Send />
                    </IconButton>
                  </Box>

                  <Link to={`/users?search=${order.consumer.email}`}>
                    {order.consumer.blocked ? (
                      <Button size="small" variant="outlined" color="secondary">
                        Unblock user
                      </Button>
                    ) : (
                      <Button size="small" variant="outlined">
                        Block user
                      </Button>
                    )}
                  </Link>
                </Box>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText>
                      <b>Date:</b>
                    </ListItemText>
                    <ListItemText className={classes.rightText}>
                      {moment(order.createdAt).format('MMMM Do YYYY, HH:mm')}
                    </ListItemText>
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemText>
                      <b>Number:</b>
                    </ListItemText>
                    <ListItemText className={classes.rightText}>{order.orderId || order.number}</ListItemText>
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemText style={{ flex: 1 }}>
                      <b>Address:</b>
                    </ListItemText>
                    <ListItemText className={classes.rightText} style={{ flex: 2 }}>
                      {order.address} {beautyAddress(order)}
                    </ListItemText>
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemText>
                      <b>Phone number: </b>
                    </ListItemText>
                    <ListItemText className={classes.rightText}>{order.phoneNumber}</ListItemText>
                  </ListItem>

                  {order.discountPercent ? (
                    <ListItem alignItems="flex-start">
                      <ListItemText>
                        <b>Discount: </b>
                      </ListItemText>
                      <ListItemText className={classes.rightText}>
                        {order.discountPercent}%{' '}
                        {order.totalPrice - getPercent(order.totalPrice, order.discountPercent)} AMD
                      </ListItemText>
                    </ListItem>
                  ) : (
                    ''
                  )}
                  <ListItem alignItems="flex-start">
                    <ListItemText>
                      <b>Delivery: </b>
                    </ListItemText>
                    <ListItemText className={classes.rightText}>{order.deliveryPrice} AMD</ListItemText>
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemText>
                      <b>Total price: </b>
                    </ListItemText>
                    <ListItemText className={classes.rightText}>
                      {order.deliveryPrice + (order.totalPrice - getPercent(order.totalPrice, order.discountPercent))}{' '}
                      AMD
                    </ListItemText>
                  </ListItem>
                  {order.payment ? (
                    <>
                      <ListItem alignItems="flex-start">
                        <ListItemText>
                          <b>Payment method</b>
                        </ListItemText>
                        <ListItemText className={classes.rightText}>{order.payment.paymentMethod}</ListItemText>
                      </ListItem>
                      {order.payment.paymentMethod === 'TELCELL' ||
                      order.payment.paymentMethod === 'CREDIT_CARD' ||
                      order.payment.paymentMethod === 'IDRAM' ? (
                        <ListItem alignItems="flex-start">
                          <ListItemText>
                            <b>Payment status</b>
                          </ListItemText>
                          <ListItemText className={classes.rightText}>{order.payment.status}</ListItemText>
                        </ListItem>
                      ) : (
                        ''
                      )}
                    </>
                  ) : (
                    ''
                  )}
                  {order.description && (
                    <>
                      <ListItem alignItems="center">
                        <ListItemText>
                          <b>Description: </b>
                        </ListItemText>
                      </ListItem>
                      <ListItem>
                        <ListItemText>{order.description}</ListItemText>
                      </ListItem>
                    </>
                  )}
                </List>
                <List className={classes.root}>
                  {order.orderItems.map((orderItem) => (
                    <React.Fragment key={orderItem._id}>
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          <Avatar
                            alt={orderItem?.item?.itemName}
                            src={`${process.env.REACT_APP_IMAGE_URL}/${orderItem.item?.image}`}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={orderItem?.item?.itemName}
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                              >
                                {orderItem?.additives.map((v) => v.additiveName).join(', ')}
                              </Typography>
                              <span className={classes.comment}>
                                <span> {orderItem?.comment}</span>
                              </span>
                              <span className={classes.priceBlock}>
                                <span> — Quantity {orderItem?.quantity}</span>
                                <span>{orderItem?.item?.price} AMD</span>
                              </span>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </React.Fragment>
                  ))}
                </List>
              </CardContent>
            </Card>
          ))
        )}
      </Container>

      <UserSendNotificationModal order={modalOrder} isOpen={!!modalOrder} onClose={() => setModalOrder(null)} />
    </React.Fragment>
  );
};

const mapStateToProps = () => ({
  store: {},
});

const mapDispatchToProps = (dispatch) => ({
  printOrderPending(orderId) {
    dispatch(printOrderPending(orderId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
