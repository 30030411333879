export const ADD_ADDITIVE_PENDING = 'ADD_ADDITIVE_PENDING';
export const ADD_ADDITIVE_SUCCESS = 'ADD_ADDITIVE_SUCCESS';
export const ADD_ADDITIVE_ERROR = 'ADD_ADDITIVE_ERROR';

export function addAdditivePending(additive) {
  return {
    type: ADD_ADDITIVE_PENDING,
    payload: additive,
  };
}

export function addAdditiveSuccess(response) {
  return {
    type: ADD_ADDITIVE_SUCCESS,
    payload: response,
  };
}

export function addAdditiveError(error) {
  return {
    type: ADD_ADDITIVE_ERROR,
    payload: error,
  };
}
