import { axios } from '../../helpers';
import { call, put, takeLatest } from 'redux-saga/effects';
import { ADD_CATEGORY_PENDING, addCategorySuccess, addCategoryError } from '../../actions';

export function* addCategory() {
  yield takeLatest(ADD_CATEGORY_PENDING, function* ({ payload }) {
    try {
      const response = yield call(axios.post, 'api/categories', payload);
      yield put(addCategorySuccess(response.data));
    } catch (e) {
      yield put(addCategoryError(e.response.data.message));
    }
  });
}
