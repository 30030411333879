import { axios } from '../../helpers';
import { call, put, takeLatest } from 'redux-saga/effects';
import { ADD_ADDITIVE_PENDING, addAdditiveSuccess, addAdditiveError } from '../../actions';

export function* addAdditive() {
  yield takeLatest(ADD_ADDITIVE_PENDING, function* ({ payload }) {
    try {
      const response = yield call(axios.post, 'api/additives', payload);
      yield put(addAdditiveSuccess(response.data));
    } catch (e) {
      yield put(addAdditiveError(e.response.data.message));
    }
  });
}
