import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginLeft: theme.spacing(1),
    minWidth: '100%',
  },
}));

export default function Option({ handleChange, defaultValue = '', items = [], label = '' }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(defaultValue);
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);
  const handleInputChange = (event) => {
    setValue(event.target.value);
    handleChange(event.target.value);
  };

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel ref={inputLabel} id="outlined-label">
        {label}
      </InputLabel>
      <Select
        labelId="outlined-label"
        id="select-outlined"
        value={value}
        onChange={handleInputChange}
        labelWidth={labelWidth}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {items.map((v) => (
          <MenuItem key={v._id} value={v._id}>
            {v.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
