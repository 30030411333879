import axios from 'axios';
import userHelper from './userHelper';

let authToken = userHelper.loggedInState ? `Bearer ${localStorage.getItem('token')}` : null;
axios.defaults.headers.common['Authorization'] = authToken;

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export default instance;
