import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';

// import EditIcon from '@material-ui/icons/Edit';
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import NavigationIcon from '@material-ui/icons/Navigation';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      position: 'fixed',
      right: 10,
      bottom: 10,
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const FloatingActionButtons = function ({ extraClass, ...props }) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, extraClass)} {...props}>
      <Fab color="primary" aria-label="add">
        <AddIcon />
      </Fab>
    </div>
  );
};

export default FloatingActionButtons;
