import { axios } from '../../helpers';
import { call, put, takeLatest } from 'redux-saga/effects';
import { FETCH_CATEGORIES_PENDING, fetchCategoriesSuccess, fetchCategoriesError } from '../../actions';

export function* fetchCategories() {
  yield takeLatest(FETCH_CATEGORIES_PENDING, function* () {
    try {
      const response = yield call(axios.get, 'api/categories');
      yield put(fetchCategoriesSuccess(response.data));
    } catch (error) {
      yield put(fetchCategoriesError(error));
    }
  });
}
