import { combineReducers } from 'redux';
import users from './users';

import { fetchCategoriesReducer, addCategoryReducer, editCategoryReducer } from './categories';
import { fetchAdditivesReducer, addAdditiveReducer, editAdditiveReducer } from './additives';
import { fetchItemsReducer, addItemReducer, editItemReducer } from './items';
import { fetchSettingsReducer, editSettingsReducer } from './settings';
import { printReducer } from './printReducer';

const rootReducer = combineReducers({
  users,
  fetchCategoriesReducer,
  addCategoryReducer,
  editCategoryReducer,

  fetchItemsReducer,
  addItemReducer,
  editItemReducer,

  fetchAdditivesReducer,
  addAdditiveReducer,
  editAdditiveReducer,
  printReducer,

  fetchSettingsReducer,
  editSettingsReducer,
});

export default rootReducer;
