import React, { useState, useEffect, useRef } from 'react';
import Container from '@material-ui/core/Container';
import AddButton from '../components/AddButton';
import AdditiveDialog from '../components/AdditiveDialog';
import { fetchAdditivesPending } from '../actions';
import { connect } from 'react-redux';
import { axios } from '../helpers/';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';

const Additives = ({ getAdditives, additives, addAdditiveReducer }) => {
  const didMountRef = useRef(false);
  const [state, setState] = useState({ openDialog: false, additive: {} });

  useEffect(() => {
    if (didMountRef.current) {
      if (addAdditiveReducer.success) {
        getAdditives();
        addAdditiveReducer.success = null;
      }
    } else {
      getAdditives();
      didMountRef.current = true;
    }
  });

  const handleOpenDialog = () => {
    setState({ ...state, openDialog: true });
  };

  const handleCloseDialog = () => {
    setState({ ...state, openDialog: false });
  };

  const editAdditive = (additive) => (e) => {
    setState({ ...state, additive, openDialog: true });
    e.preventDefault();
  };

  const deleteAdditive = (id) => async (e) => {
    e.preventDefault();
    try {
      await axios.delete(`api/additives/${id}`);
      getAdditives();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Container>
      <Card>
        <CardContent>
          <List>
            {additives.length ? (
              additives.map((additive) => (
                <ListItem button key={additive._id}>
                  <ListItemText primary={additive.additiveName} />

                  <ListItemSecondaryAction>
                    <IconButton aria-label="edit" onClick={editAdditive(additive)}>
                      <EditIcon className="additive-items__actions-edit" />
                    </IconButton>

                    <IconButton edge="end" aria-label="delete" onClick={deleteAdditive(additive._id)}>
                      <DeleteIcon className="additive-items__actions-delete" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))
            ) : (
              <h4>No Additives Found</h4>
            )}
          </List>
        </CardContent>
      </Card>

      <AddButton onClick={handleOpenDialog} />

      <AdditiveDialog additive={state.additive} open={state.openDialog} handleClose={handleCloseDialog} />
    </Container>
  );
};

const mapStateToProps = ({ fetchAdditivesReducer, addAdditiveReducer }) => ({
  additives: fetchAdditivesReducer.additives,
  addAdditiveReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getAdditives() {
    dispatch(fetchAdditivesPending());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Additives);
