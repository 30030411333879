import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { axios } from '../helpers';
import { useSnackbar } from 'notistack';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    margin: 'auto',
    maxWidth: 500,
  },
  textarea: {
    marginBottom: theme.spacing(2),
  },
  notificationUsersCount: {
    color: 'red',
    fontSize: '30px',
  },
}));

const LoadingButton = ({ children, loading, ...props }) => (
  <Button {...props}>
    {loading || children}
    {loading && <CircularProgress color="info" size={24} />}
  </Button>
);

function NotificationsCreate(props) {
  const [modalIsShown, setModalIsShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  const [state, setState] = useState({ title: '', body: '' });
  const { enqueueSnackbar } = useSnackbar();

  const handleOpenDialog = (e) => {
    e?.preventDefault();

    setModalIsShown(true);
  };

  const handleCloseDialog = () => {
    setModalIsShown(false);
  };

  const sendNotification = async () => {
    handleCloseDialog();

    try {
      setLoading(true);
      const notification = await axios.post(`${process.env.REACT_APP_API_URL}/api/notifications`, {
        title: state.title,
        body: state.body,
        consumers: [],
      });

      if (notification.status === 200) {
        enqueueSnackbar('Notification sent.', {
          autoHideDuration: 2000,
          anchorOrigin: { horizontal: 'right', vertical: 'top' },
          variant: 'success',
        });
        props.history.push('/notifications');
      }
    } catch (error) {
      console.log(error);

      enqueueSnackbar('Something went wrong. Please try again', {
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: 'right', vertical: 'top' },
        variant: 'error',
        onClose() {
          props.history.push('/notifications');
        },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      <form onSubmit={handleOpenDialog} autoComplete="off">
        <Paper className={classes.paper}>
          <Grid item xs container direction="column" spacing={2}>
            <Typography>
              <strong>
                Send new notification to <span className={classes.notificationUsersCount}> ALL </span> users
              </strong>
            </Typography>

            <TextField
              label="Title"
              margin="dense"
              variant="outlined"
              required
              onChange={({ target }) => setState({ ...state, title: target.value })}
            />
            <TextField
              label="Description"
              className={classes.textarea}
              margin="dense"
              variant="outlined"
              multiline
              required
              value={state.body}
              onChange={({ target }) => setState({ ...state, body: target.value })}
              rows={4}
            />
            <LoadingButton disabled={loading} loading={loading} variant="contained" type="submit" color="primary">
              Send Notification
            </LoadingButton>
          </Grid>
        </Paper>
      </form>

      <Dialog onClose={handleCloseDialog} open={modalIsShown}>
        <DialogTitle color="error" id="alert-dialog-title">
          <strong>Send Notification</strong>
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <strong>
              You're about to send this notification to <span className={classes.notificationUsersCount}> ALL </span>{' '}
              users
            </strong>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={sendNotification}>Send</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default NotificationsCreate;
