import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Icon from '@material-ui/core/Icon';
import {
  dashboardRoutes,
  // sectionRoutes
} from '../routes';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
}));

function DrawerContent(props) {
  const classes = useStyles();

  function goToRoute(route) {
    props.history.push(route);
  }

  return (
    <div>
      <div className={classes.toolbar}>
        <img width="265" src="/pitstop-logo.jpg" onClick={() => goToRoute('/')} alt="Logo" />
      </div>
      <Divider />
      <List
        subheader={
          <ListSubheader component="div" id="editr-or-add-users">
            Dashboard Routes
          </ListSubheader>
        }
      >
        {dashboardRoutes
          .filter((v) => v.isVisible)
          .map((route, key) => (
            <ListItem button key={key} onClick={() => goToRoute(route.path)}>
              <ListItemIcon>
                <Icon>{route.icon}</Icon>
              </ListItemIcon>
              <ListItemText primary={route.name} />
            </ListItem>
          ))}
      </List>
      <Divider />
      {/* <List subheader={
                <ListSubheader component="div" id="edit-public-sections">
                    Sections Routes
                </ListSubheader>
            }>
                {sectionRoutes.map((route, key) => (
                    <ListItem button key={key} onClick={() => goToRoute(route.path)}>
                        <ListItemIcon><Icon>{route.icon}</Icon></ListItemIcon>
                        <ListItemText primary={route.name}/>
                    </ListItem>
                ))}
            </List> */}
    </div>
  );
}

export default withRouter(DrawerContent);
