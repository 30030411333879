import React from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';

function MyApp({ variant, message }) {
  const { enqueueSnackbar } = useSnackbar();

  enqueueSnackbar(message, {
    variant,
    autoHideDuration: 2000,
    anchorOrigin: { horizontal: 'right', vertical: 'top' },
  });

  return <React.Fragment />;
}

export default function IntegrationNotistack({ variant, message, showSnackMessage, onClose }) {
  return (
    <SnackbarProvider onClose={onClose} maxSnack={3}>
      <MyApp variant={variant} message={message} showSnackMessage={showSnackMessage} />
    </SnackbarProvider>
  );
}
