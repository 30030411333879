import { axios } from '../../helpers';
import { call, put, takeLatest } from 'redux-saga/effects';
import { FETCH_ITEMS_PENDING, fetchItemsSuccess, fetchItemsError } from '../../actions';

export function* fetchItems() {
  yield takeLatest(FETCH_ITEMS_PENDING, function* ({ payload }) {
    const query = { params: {} };
    if (payload) {
      query.params.category = payload;
    }

    try {
      const response = yield call(axios.get, 'api/items', query);
      yield put(fetchItemsSuccess(response.data));
    } catch (error) {
      yield put(fetchItemsError(error));
    }
  });
}
