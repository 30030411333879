// import React from 'react';
import Dashboard from './Views/Dashboard';
import Categories from './Views/Categories';
import Items from './Views/Items';
import Additives from './Views/Additives';
import Users from './Views/Users';
import Orders from './Views/Orders';
import Notifications from './Views/Notifications';
import NotificationsCreate from './Views/NotificationsCreate';
import Settings from './Views/Settings';

export const dashboardRoutes = [
  {
    name: 'Dashboard',
    path: '/',
    exact: true,
    component: Dashboard,
    icon: 'dashboard',
    isVisible: true,
  },
  {
    name: 'Categories',
    path: '/categories',
    exact: true,
    component: Categories,
    icon: 'category',
    isVisible: true,
  },
  {
    name: 'Items',
    path: '/items',
    exact: true,
    component: Items,
    icon: 'fastfood',
    isVisible: true,
  },
  {
    name: 'categoryItems',
    path: '/items/:categoryId',
    exact: true,
    component: Items,
    icon: 'fastfood',
    isVisible: false,
  },
  {
    name: 'Additives',
    path: '/additives',
    exact: true,
    component: Additives,
    icon: 'eco',
    isVisible: true,
  },
  {
    name: 'Orders',
    path: '/orders',
    exact: true,
    component: Orders,
    icon: 'reorder',
    isVisible: true,
  },
  {
    name: 'Users',
    path: '/users',
    exact: true,
    component: Users,
    icon: 'person',
    isVisible: true,
  },
  {
    name: 'Notifications',
    path: '/notifications',
    exact: true,
    component: Notifications,
    icon: 'notifications',
    isVisible: true,
  },
  {
    name: 'Notifications create',
    path: '/notifications/create',
    exact: true,
    component: NotificationsCreate,
    icon: 'notifications',
    isVisible: false,
  },
  {
    name: 'Settings',
    path: '/settings',
    exact: true,
    component: Settings,
    icon: 'settings',
    isVisible: true,
  },
];

export default dashboardRoutes;
