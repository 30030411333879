import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import AddButton from '../components/AddButton';
import { axios } from '../helpers';
import moment from 'moment';

const columns = [
  { id: 'title', label: 'Title', minWidth: 170 },
  { id: 'body', label: 'Description', minWidth: 100 },
  { id: 'noPermissionCount', label: 'No Permission', align: 'center', minWidth: 140 },
  { id: 'successCount', label: 'Success', align: 'center', minWidth: 140 },
  { id: 'errorCount', label: 'Error', align: 'center', minWidth: 120 },
  { id: 'createdAt', label: 'Date', align: 'right', minWidth: 150 },
];

const useStyles = makeStyles({
  addButtonRoot: {
    '& > *': {
      bottom: 0,
    },
  },
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 430,
  },
});

export default function Notifications(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [notifications, setNotifications] = React.useState([]);
  const [notificationsTotal, setNotificationsTotal] = React.useState(0);

  const getNotifications = (page, rowsPerPage) => {
    const params = {
      rowsPerPage,
      page,
    };
    return axios.get(`${process.env.REACT_APP_API_URL}/api/notifications`, { params });
  };
  React.useEffect(() => {
    getNotifications(page, rowsPerPage).then((v) => {
      setNotifications(v.data.result);
      setNotificationsTotal(v.data.total);
    });
  }, []);

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    const { data } = await getNotifications(newPage, rowsPerPage);
    setNotifications(data.result);
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    const { data } = await getNotifications(0, event.target.value);
    setNotifications(data.result);
  };
  const handleRedirect = () => {
    props.history.push('/notifications/create');
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {notifications.map((row) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <TableCell key={column.id} align={column.align}>
                      {column.id === 'createdAt' ? moment(value).format('MM-DD-YYYY, HH:mm') : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[30, 70, 150]}
        component="div"
        count={notificationsTotal}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <AddButton extraClass={classes.addButtonRoot} onClick={handleRedirect} />
    </Paper>
  );
}
