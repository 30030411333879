const initialState = {
  me: {
    settings: {
      discountPercent: 0,
      minOrderPrice: 0,
      centerDeliveryPrice: 0,
      outOfCenterDeliveryPrice: 0,
      sleepMode: false,
      centerRadius: 0,
      outOfCenterMinPrice: 0,
      outOfCenterThresholdPrice: 0,
    },
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'GET_ME':
      return {
        ...state,
      };

    case 'GET_ME_SUCCESS':
      return {
        ...state,
        me: payload.user,
      };

    case 'GET_ME_ERROR':
      return {
        ...state,
        me: payload,
      };

    default:
      return state;
  }
};
