export const EDIT_CATEGORY_PENDING = 'EDIT_CATEGORY_PENDING';
export const EDIT_CATEGORY_SUCCESS = 'EDIT_CATEGORY_SUCCESS';
export const EDIT_CATEGORY_ERROR = 'EDIT_CATEGORY_ERROR';

export function editCategoryPending(category) {
  return {
    type: EDIT_CATEGORY_PENDING,
    payload: category,
  };
}

export function editCategorySuccess(response) {
  return {
    type: EDIT_CATEGORY_SUCCESS,
    payload: response,
  };
}

export function editCategoryError(error) {
  return {
    type: EDIT_CATEGORY_ERROR,
    payload: error,
  };
}
