export default ({ entrance, apartment, floor }) => {
  let fullAddress = '';

  if (apartment) {
    fullAddress += `apt. ${apartment} `;
  }

  if (entrance) {
    fullAddress += `ent. ${entrance}, `;
  }

  if (floor) {
    fullAddress += `floor ${floor}, `;
  }

  return fullAddress;
};
