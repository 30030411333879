import { EDIT_SETTINGS_PENDING, EDIT_SETTINGS_SUCCESS, EDIT_SETTINGS_ERROR } from '../../actions';

const initialState = { success: null, error: null, errorMsg: '' };

export const editSettingsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case EDIT_SETTINGS_PENDING:
      return {
        ...state,
        success: false,
        error: false,
      };

    case EDIT_SETTINGS_SUCCESS:
      return {
        ...state,
        success: true,
        error: null,
        ...payload,
      };

    case EDIT_SETTINGS_ERROR:
      return {
        ...state,
        error: true,
        success: null,
        errorMsg: payload,
      };

    default:
      return state;
  }
};
