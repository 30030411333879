import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  fetchCategoriesPending,
  addItemPending,
  fetchItemsPending,
  editItemPending,
  fetchAdditivesPending,
} from '../actions';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Select from './Select';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  img: {
    width: 200,
    height: '100%',
  },
  input: {
    display: 'none',
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class ItemModel {
  constructor(categoryId = '') {
    this.category = categoryId;
    this.itemName = '';
    this.image = '';
    this.price = '';
    this.subName = '';
    this.description = '';
    this.systemItemName = '';
    this.additives = [];
  }
}

const ItemDialog = (props) => {
  const { categoryId: categoryIdParam } = useParams();

  const {
    categoryId = categoryIdParam || '',
    open,
    handleClose,
    categories = [props.item.category],
    getCategories,
    getAdditives,
    addItem,
    addItemReducer,
    getItems,
    item,
    editItem,
    editItemReducer,
    additives,
  } = props;

  const didMountRef = useRef(false);

  const closeDialog = () => {
    const item = new ItemModel();

    setState({ ...state, ...item, previewUrl: '' });
    handleClose();
  };

  const setInputValues = () => {
    setState({ ...state, ...item, previewUrl: `${process.env.REACT_APP_API_URL}/uploads/${item.image}` });
  };

  useEffect(() => {
    if (didMountRef.current) {
      if (addItemReducer.success) {
        closeDialog();
      }
      if (item._id && !item.edit) {
        setInputValues();
        item.edit = true;
      }

      if (editItemReducer.success) {
        closeDialog();
        getItems(categoryId);
        editItemReducer.success = null;
      }
    } else {
      didMountRef.current = true;
      getCategories();
      getAdditives();
    }
  });

  const classes = useStyles();

  const [state, setState] = useState({
    ...new ItemModel(categoryId),
    previewUrl: '',
  });

  const setInputValue = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleChange = (c) => {
    setState({ ...state, category: c });
  };

  const handleChangeAdditive = (additive) => {
    let additives;

    const isExists = state.additives.find((a) => a._id === additive._id);

    if (isExists) {
      additives = state.additives.filter((a) => a._id !== additive._id);
    } else {
      additives = [...state.additives, additive];
    }

    setState({ ...state, additives });
  };

  const handleSave = () => {
    const itemModel = new ItemModel(categoryId);
    const formData = new FormData();

    for (const key in itemModel) {
      if (key === 'image') {
        formData.append(key, state[key]);
      } else {
        formData.append(key, JSON.stringify(state[key]));
      }
    }

    if (item._id) {
      editItem({ formData, itemId: item._id });
    } else {
      addItem(formData);
    }
  };

  const uploadImage = (e) => {
    try {
      const previewUrl = URL.createObjectURL(e.target.files[0]);
      setState({ ...state, previewUrl, image: e.target.files[0] });
    } catch (error) {
      console.log(error);
    }
  };

  categories.map((v) => (v.name = v.categoryName));

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {item._id ? 'Edit product' : 'Add product'}
          </Typography>
          <Button autoFocus color="inherit" onClick={handleSave}>
            {item._id ? 'save' : 'add'}
          </Button>
        </Toolbar>
      </AppBar>
      <form className={classes.container}>
        <Container fixed>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={6}>
              <Button component="label">
                <img
                  title="Choose image"
                  className={classes.img}
                  alt=""
                  src={state.previewUrl || `${process.env.REACT_APP_API_URL}/assets/burger.jpg`}
                />
                <input onChange={uploadImage} className={classes.input} type="file" />
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <TextField
                id="outlined-helperText"
                label="Description"
                name="description"
                value={state.description}
                className={classes.textField}
                margin="normal"
                variant="outlined"
                onChange={setInputValue}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <TextField
                id="outlined-helperText"
                label="Name"
                name="itemName"
                value={state.itemName}
                className={classes.textField}
                margin="dense"
                variant="outlined"
                onChange={setInputValue}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <TextField
                id="outlined-helperText"
                label="Sub Name"
                name="subName"
                value={state.subName}
                className={classes.textField}
                margin="dense"
                variant="outlined"
                onChange={setInputValue}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <TextField
                id="outlined-helperText"
                label="Price"
                name="price"
                value={state.price}
                className={classes.textField}
                margin="dense"
                variant="outlined"
                onChange={setInputValue}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={6}>
              <Select
                defaultValue={categoryId || (item.category && item.category._id)}
                className={classes.textField}
                items={categories}
                handleChange={handleChange}
                label="Category"
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={6}>
              <TextField
                id="pos-name"
                label="System Item Name"
                name="systemItemName"
                value={state.systemItemName}
                className={classes.textField}
                margin="dense"
                variant="outlined"
                onChange={setInputValue}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={6}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Additives</FormLabel>
                <FormGroup>
                  {additives.map((additive) => (
                    <FormControlLabel
                      key={additive._id}
                      label={additive.additiveName}
                      control={
                        <Checkbox
                          color="primary"
                          checked={!!state.additives.find((a) => a._id === additive._id)}
                          onChange={() => handleChangeAdditive(additive)}
                          value={additive.additiveName}
                        />
                      }
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Container>
      </form>
    </Dialog>
  );
};

const mapStateToProps = ({ fetchAdditivesReducer, fetchCategoriesReducer, addItemReducer, editItemReducer }) => ({
  categories: fetchCategoriesReducer.categories,
  additives: fetchAdditivesReducer.additives,
  addItemReducer,
  editItemReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getCategories() {
    dispatch(fetchCategoriesPending());
  },
  getAdditives() {
    dispatch(fetchAdditivesPending());
  },
  addItem(item) {
    dispatch(addItemPending(item));
  },
  editItem(item) {
    dispatch(editItemPending(item));
  },
  getItems(categoryId) {
    dispatch(fetchItemsPending(categoryId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemDialog);
