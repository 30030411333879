export const FETCH_ITEMS_PENDING = 'FETCH_ITEMS_PENDING';
export const FETCH_ITEMS_SUCCESS = 'FETCH_ITEMS_SUCCESS';
export const FETCH_ITEMS_ERROR = 'FETCH_ITEMS_ERROR';

export function fetchItemsPending(categoryId) {
  return {
    type: FETCH_ITEMS_PENDING,
    payload: categoryId,
  };
}

export function fetchItemsSuccess(categories) {
  return {
    type: FETCH_ITEMS_SUCCESS,
    payload: categories,
  };
}

export function fetchItemsError(error) {
  return {
    type: FETCH_ITEMS_ERROR,
    error: error,
  };
}
