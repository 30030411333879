import { ADD_ITEM_PENDING, ADD_ITEM_SUCCESS, ADD_ITEM_ERROR } from '../../actions';

const initialState = {
  success: null,
  error: null,
};

export const addItemReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_ITEM_PENDING:
      return {
        ...state,
        success: false,
        error: null,
      };

    case ADD_ITEM_SUCCESS:
      return {
        ...state,
        success: true,
        error: null,
      };

    case ADD_ITEM_ERROR:
      console.log(payload);

      return {
        ...state,
        success: false,
        error: payload,
      };
    default:
      return state;
  }
};
