export const ADD_CATEGORY_PENDING = 'ADD_CATEGORY_PENDING';
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS';
export const ADD_CATEGORY_ERROR = 'ADD_CATEGORY_ERROR';

export function addCategoryPending(category) {
  return {
    type: ADD_CATEGORY_PENDING,
    payload: category,
  };
}

export function addCategorySuccess(response) {
  return {
    type: ADD_CATEGORY_SUCCESS,
    payload: response,
  };
}

export function addCategoryError(error) {
  return {
    type: ADD_CATEGORY_ERROR,
    payload: error,
  };
}
