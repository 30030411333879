import { FETCH_SETTINGS_PENDING, FETCH_SETTINGS_SUCCESS, FETCH_SETTINGS_ERROR } from '../../actions';

const initialState = {
  items: [],
  success: null,
  error: false,
};

export const fetchSettingsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_SETTINGS_PENDING:
      return {
        ...state,
        success: false,
      };

    case FETCH_SETTINGS_SUCCESS:
      return {
        ...state,
        items: payload,
        success: true,
      };

    case FETCH_SETTINGS_ERROR:
      return {
        ...state,
        success: false,
        error: true,
      };
    default:
      return state;
  }
};
