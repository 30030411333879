import { axios } from '../../helpers';
import { call, put, takeLatest } from 'redux-saga/effects';
import { EDIT_CATEGORY_PENDING, editCategorySuccess, editCategoryError } from '../../actions';

export function* editCategory() {
  yield takeLatest(EDIT_CATEGORY_PENDING, function* ({ payload }) {
    try {
      const response = yield call(axios.put, `api/categories/${payload.categoryId}`, payload.formData);
      yield put(editCategorySuccess(response.data));
    } catch (e) {
      yield put(editCategoryError(e.response.data.message));
    }
  });
}
