import { EDIT_ADDITIVE_PENDING, EDIT_ADDITIVE_SUCCESS, EDIT_ADDITIVE_ERROR } from '../../actions';

const initialState = {
  success: null,
  error: null,
};

export const editAdditiveReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case EDIT_ADDITIVE_PENDING:
      return {
        ...state,
        success: false,
        error: null,
      };

    case EDIT_ADDITIVE_SUCCESS:
      return {
        ...state,
        success: true,
        error: null,
      };

    case EDIT_ADDITIVE_ERROR:
      return {
        ...state,
        success: false,
        error: payload,
      };
    default:
      return state;
  }
};
