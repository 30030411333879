import React, { Component } from 'react';
import { BrowserRouter, Redirect, Switch, Route } from 'react-router-dom';
import './App.scss';
import Layout from './Views/Layout';
import Login from './Views/Login';
// import NotFound from "./Views/404";
import { userHelper } from './helpers';
import { Provider } from 'react-redux';
import configureStore from './configureStore';
import { SnackbarProvider } from 'notistack';

const initialState = {};
const store = configureStore(initialState);

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        userHelper.loggedInState ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

class App extends Component {
  render() {
    return (
      <SnackbarProvider maxSnack={3}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Provider store={store}>
              <PrivateRoute component={Layout} path={'/'} />
              {/*<Route component={NotFound}/>*/}
            </Provider>
          </Switch>
        </BrowserRouter>
      </SnackbarProvider>
    );
  }
}

export default App;
