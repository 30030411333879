import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { fetchCategoriesPending, editCategoryPending } from '../actions';

import { addCategoryPending } from '../actions';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  img: {
    width: 200,
    height: '100%',
  },
  input: {
    display: 'none',
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class ItemModel {
  constructor() {
    this.categoryName = '';
    this.image = '';
  }
}

const CategoryDialog = (props) => {
  const {
    open,
    handleClose,
    addCategory,
    addCategoryReducer,
    category,
    getCategories,
    editCategory,
    editCategoryReducer,
  } = props;
  const didMountRef = useRef(false);

  const classes = useStyles();
  const [state, setState] = useState({
    ...new ItemModel(),
    previewUrl: '',
  });

  const setInputValue = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const closeDialog = () => {
    const category = new ItemModel();

    setState({ ...state, ...category, previewUrl: '' });
    handleClose();
  };

  const setInputValues = () => {
    setState({ ...state, ...category, previewUrl: `${process.env.REACT_APP_API_URL}/uploads/${category.image}` });
  };

  useEffect(() => {
    if (didMountRef.current) {
      if (addCategoryReducer.success) {
        closeDialog();
      }
      if (category._id && !category.edit) {
        setInputValues();
        category.edit = true;
      }

      if (editCategoryReducer.success) {
        closeDialog();
        getCategories();
        editCategoryReducer.success = null;
      }
    } else {
      didMountRef.current = true;
    }
  });

  const handleSave = async () => {
    const categoryModel = new ItemModel();
    const formData = new FormData();

    for (const key in categoryModel) {
      if (key === 'image') {
        formData.append(key, state[key]);
      } else {
        formData.append(key, JSON.stringify(state[key]));
      }
    }

    if (category._id) {
      editCategory({ formData, categoryId: category._id });
    } else {
      addCategory(formData);
    }
  };

  const uploadImage = (e) => {
    try {
      const previewUrl = URL.createObjectURL(e.target.files[0]);
      setState({ ...state, previewUrl, image: e.target.files[0] });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {category._id ? 'Edit cateogry' : 'Add category'}
          </Typography>
          <Button autoFocus color="inherit" onClick={handleSave}>
            {category._id ? 'save' : 'add'}
          </Button>
        </Toolbar>
      </AppBar>
      <form className={classes.container}>
        <Container fixed>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={6}>
              <Button component="label">
                <img
                  title="Choose image"
                  className={classes.img}
                  alt=""
                  src={state.previewUrl || `${process.env.REACT_APP_API_URL}/assets/burger.jpg`}
                />
                <input onChange={uploadImage} className={classes.input} type="file" />
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <TextField
                id="outlined-helperText"
                label="Name"
                name="categoryName"
                value={state.categoryName}
                className={classes.textField}
                margin="dense"
                variant="outlined"
                onChange={setInputValue}
              />
            </Grid>
          </Grid>
        </Container>
      </form>
    </Dialog>
  );
};

const mapStateToProps = ({ addCategoryReducer, editCategoryReducer }) => ({
  addCategoryReducer,
  editCategoryReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getCategories() {
    dispatch(fetchCategoriesPending());
  },
  editCategory(category) {
    dispatch(editCategoryPending(category));
  },
  addCategory(category) {
    dispatch(addCategoryPending(category));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryDialog);
