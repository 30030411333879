export const EDIT_SETTINGS_PENDING = 'EDIT_SETTINGS_PENDING';
export const EDIT_SETTINGS_SUCCESS = 'EDIT_SETTINGS_SUCCESS';
export const EDIT_SETTINGS_ERROR = 'EDIT_SETTINGS_ERROR';

export function editSettingsPending(payload) {
  return {
    type: EDIT_SETTINGS_PENDING,
    payload,
  };
}

export function editSettingsSuccess(payload) {
  return {
    type: EDIT_SETTINGS_SUCCESS,
    payload,
  };
}

export function editSettingsError(payload) {
  return {
    type: EDIT_SETTINGS_ERROR,
    payload,
  };
}
