import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { axios } from '../helpers';
import { useSnackbar } from 'notistack';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InfoIcon from '@material-ui/icons/Info';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import Slide from '@material-ui/core/Slide';
import moment from 'moment/moment';
import TodayIcon from '@material-ui/icons/Today';
import Grid from '@material-ui/core/Grid';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    margin: 'auto',
    maxWidth: 500,
  },
  textarea: {
    marginBottom: theme.spacing(2),
  },
  notificationUsersCount: {
    color: 'red',
    fontSize: '30px',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function UserSendNotificationModal(props) {
  const { isOpen, onClose, order } = props;

  const classes = useStyles();
  const [state, setState] = useState({ title: '', body: '' });
  const [confirmIsOpen, setConfirmDialogIsOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSendNotification = async (e) => {
    e.preventDefault();
    handleClose();

    if (!order || !order.consumer?._id) return;

    try {
      const notification = await axios.post(`${process.env.REACT_APP_API_URL}/api/notifications`, {
        title: state.title,
        body: state.body,
        consumers: [order?.consumer._id],
      });

      if (notification.status === 200) {
        enqueueSnackbar('Notification sent.', {
          autoHideDuration: 2000,
          anchorOrigin: { horizontal: 'right', vertical: 'top' },
          variant: 'success',
        });
      }
    } catch (error) {
      enqueueSnackbar('Something went wrong.', {
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: 'right', vertical: 'top' },
        variant: 'error',
      });

      console.log(error);
    }
  };

  const handleClose = () => {
    onClose();
    handleCloseConfirmDialog();
  };

  const handleOpenConfirm = (e) => {
    e.preventDefault();
    setConfirmDialogIsOpen(true);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogIsOpen(false);
  };

  return (
    <>
      <Dialog fullWidth={true} maxWidth="md" onClose={handleClose} open={isOpen} TransitionComponent={Transition}>
        <DialogTitle color="error" id="alert-dialog-title">
          <strong>Send Notification</strong>
        </DialogTitle>

        <DialogContent>
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={6}>
              <List>
                <ListItem style={{ paddingLeft: 0, paddingRight: 0 }}>
                  <ListItemIcon>
                    <TodayIcon />
                  </ListItemIcon>
                  <ListItemText>
                    Date: <strong>{moment(order?.createdAt).format('MMMM Do YYYY, HH:mm')}</strong>
                  </ListItemText>
                </ListItem>
                <ListItem style={{ paddingLeft: 0, paddingRight: 0 }}>
                  <ListItemIcon>
                    <HomeWorkIcon />
                  </ListItemIcon>
                  <ListItemText>
                    Address: <strong>{order?.takeAway ? 'Take Away' : order?.address}</strong>
                  </ListItemText>
                </ListItem>
                <ListItem style={{ paddingLeft: 0, paddingRight: 0 }}>
                  <ListItemIcon>
                    <FastfoodIcon />
                  </ListItemIcon>
                  <ListItemText>
                    Order ID: <strong>{order?.orderId}</strong>
                  </ListItemText>
                </ListItem>

                <ListItem style={{ paddingLeft: 0, paddingRight: 0 }}>
                  <ListItemIcon>
                    <InfoIcon />
                  </ListItemIcon>
                  <ListItemText>
                    Client ID: <strong>{order?.consumer?._id}</strong>
                  </ListItemText>
                </ListItem>

                {order?.phoneNumber && (
                  <ListItem style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <ListItemIcon>
                      <PhoneIcon />
                    </ListItemIcon>
                    <ListItemText>
                      Order Phone Number: <strong>{order?.phoneNumber}</strong>
                    </ListItemText>
                  </ListItem>
                )}

                {order?.consumer?.phoneNumber && (
                  <ListItem style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <ListItemIcon>
                      <ContactPhoneIcon />
                    </ListItemIcon>

                    <ListItemText>
                      Client Phone Number: <strong>{order?.consumer?.phoneNumber}</strong>
                    </ListItemText>
                  </ListItem>
                )}

                {order?.consumer?.email && (
                  <ListItem style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <ListItemIcon>
                      <EmailIcon />
                    </ListItemIcon>
                    <ListItemText>
                      Email: <strong>{order?.consumer?.email}</strong>
                    </ListItemText>
                  </ListItem>
                )}
              </List>
            </Grid>
            <Grid item xs={6}>
              <Box flex={1}>
                <form id="one-user-notification-form" autoComplete="off" onSubmit={handleOpenConfirm}>
                  <Box display="flex" flexDirection="column">
                    <TextField
                      label="Title"
                      margin="dense"
                      variant="outlined"
                      required
                      onChange={({ target }) => setState({ ...state, title: target.value })}
                    />

                    <TextField
                      label="Description"
                      className={classes.textarea}
                      margin="dense"
                      variant="outlined"
                      required
                      value={state.body}
                      onChange={({ target }) => setState({ ...state, body: target.value })}
                      multiline
                      rows={5}
                    />
                  </Box>
                </form>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>

          <Button form="one-user-notification-form" type="submit" autoFocus variant="contained" color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog onClose={() => setConfirmDialogIsOpen(false)} open={confirmIsOpen}>
        <DialogTitle color="error" id="alert-dialog-title">
          <strong>Send Notification</strong>
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to send this notification?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseConfirmDialog}>Cancel</Button>

          <Button variant="contained" color="primary" onClick={handleSendNotification} autoFocus>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UserSendNotificationModal;
