import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import { editSettingsPending } from '../actions';

import {
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  Grid,
  Box,
  Button,
  Switch,
  FormControlLabel,
  FormLabel,
  Card,
  CardContent,
  Typography,
  Select,
  MenuItem,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  box: {
    marginTop: 24,
  },
  form: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  boxSaveButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  field: {
    marginBottom: 20,
  },
  title: {
    marginBottom: 20,
  },
}));

const Settings = (props) => {
  const classes = useStyles();

  const [state, setState] = useState({
    discountPercent: 0,
    centerDeliveryPrice: 0,
    outOfCenterDeliveryPrice: 0,
    sleepMode: false,
    allowPrintTaxRecipient: false,
    printCashRecipient: false,
    centerRadius: 0,
    outOfCenterMinPrice: 0,
    outOfCenterThresholdPrice: 0,
    printAddress: '',
  });

  useEffect(() => {
    setState((state) => ({ ...state, ...props.settings }));
  }, [props.settings]);

  const setInputValue = ({ target }) => {
    target.value >= 0 && setState({ ...state, [target.name]: parseFloat(target.value) });
  };

  const handleChangePrintAddress = (e) => {
    setState((state) => ({ ...state, printAddress: e.target.value }));
  };

  const handleToggleSwitch = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const saveChanges = async (e) => {
    e.preventDefault();
    state.restaurant = props.me.restaurant;
    props.editSettings(state);
  };

  console.log(state.printAddress);

  return (
    <div className={classes.root}>
      <form className={classes.form} onSubmit={saveChanges}>
        <Card>
          <CardContent>
            <Grid spacing={3} container>
              <Grid item xs={12} sm={3}>
                <Typography variant="h5" component="h2" color="primary" className={classes.title}>
                  App Settings
                </Typography>

                <FormControl className={classes.field} fullWidth component="fieldset">
                  <FormLabel htmlFor="print-address">Print address</FormLabel>
                  <Select
                    labelId="print-address-label"
                    id="print-address"
                    value={state.printAddress}
                    onChange={handleChangePrintAddress}
                  >
                    <MenuItem value="print-pitstop">Pushkin 37</MenuItem>
                    <MenuItem value="print-pitstop-p35">Pushkin 35</MenuItem>
                  </Select>
                </FormControl>
                <FormControl className={classes.field} fullWidth component="fieldset">
                  <FormLabel htmlFor="sleep-mode">Mobile app sleep mode</FormLabel>
                  <FormControlLabel
                    id="sleep-mode"
                    control={<Switch checked={state.sleepMode} onChange={handleToggleSwitch} name="sleepMode" />}
                    label={state.sleepMode ? 'On' : 'Off'}
                  />
                </FormControl>
                <FormControl className={classes.field} fullWidth component="fieldset">
                  <FormLabel htmlFor="allowPrintTaxRecipient">Print tax recipient</FormLabel>
                  <FormControlLabel
                    id="allowPrintTaxRecipient"
                    control={
                      <Switch
                        checked={state.allowPrintTaxRecipient}
                        onChange={handleToggleSwitch}
                        name="allowPrintTaxRecipient"
                      />
                    }
                    label={state.allowPrintTaxRecipient ? 'On' : 'Off'}
                  />
                </FormControl>
                {state.allowPrintTaxRecipient && (
                  <FormControl className={classes.field} fullWidth component="fieldset">
                    <FormLabel htmlFor="printCashRecipient">Print cash recipient</FormLabel>
                    <FormControlLabel
                      id="printCashRecipient"
                      control={
                        <Switch
                          checked={state.printCashRecipient}
                          onChange={handleToggleSwitch}
                          name="printCashRecipient"
                        />
                      }
                      label={state.printCashRecipient ? 'On' : 'Off'}
                    />
                  </FormControl>
                )}
              </Grid>

              <Grid item xs={12} sm={3}>
                <Typography variant="h5" component="h2" color="primary" className={classes.title}>
                  Order Settings
                </Typography>

                <FormControl fullWidth className={classes.field}>
                  <InputLabel htmlFor="discount-percent">Discount</InputLabel>
                  <Input
                    id="discount-percent"
                    value={state.discountPercent}
                    type="number"
                    name="discountPercent"
                    onChange={setInputValue}
                    startAdornment={<InputAdornment position="start">%</InputAdornment>}
                  />
                </FormControl>

                <FormControl fullWidth className={classes.field}>
                  <InputLabel htmlFor="center-radius">Center radius</InputLabel>
                  <Input
                    id="center-radius"
                    type="number"
                    name="centerRadius"
                    onChange={setInputValue}
                    value={state.centerRadius}
                    startAdornment={<InputAdornment position="start">KM</InputAdornment>}
                  />
                </FormControl>

                <FormControl fullWidth className={classes.field}>
                  <InputLabel htmlFor="out-of-center-min-price">Minimal price for out of center orders</InputLabel>
                  <Input
                    id="out-of-center-min-price"
                    type="number"
                    name="outOfCenterMinPrice"
                    onChange={setInputValue}
                    value={state.outOfCenterMinPrice}
                    startAdornment={<InputAdornment position="start">֏</InputAdornment>}
                  />
                </FormControl>

                <FormControl fullWidth className={classes.field}>
                  <InputLabel htmlFor="out-of-center-threshold-price">
                    Threshold price for out of center orders
                  </InputLabel>
                  <Input
                    id="out-of-center-threshold-price"
                    type="number"
                    name="outOfCenterThresholdPrice"
                    onChange={setInputValue}
                    value={state.outOfCenterThresholdPrice}
                    startAdornment={<InputAdornment position="start">֏</InputAdornment>}
                  />
                </FormControl>

                <FormControl fullWidth className={classes.field}>
                  <InputLabel htmlFor="center-delivery-price">Center delivery price</InputLabel>
                  <Input
                    id="center-delivery-price"
                    type="number"
                    name="centerDeliveryPrice"
                    onChange={setInputValue}
                    value={state.centerDeliveryPrice}
                    startAdornment={<InputAdornment position="start">֏</InputAdornment>}
                  />
                </FormControl>

                <FormControl fullWidth className={classes.field}>
                  <InputLabel htmlFor="out-of-center-delivery-price">Out of center delivery price</InputLabel>
                  <Input
                    id="out-of-center-delivery-price"
                    type="number"
                    name="outOfCenterDeliveryPrice"
                    onChange={setInputValue}
                    value={state.outOfCenterDeliveryPrice}
                    startAdornment={<InputAdornment position="start">֏</InputAdornment>}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Box className={classes.boxSaveButton}>
              <Button type="submit" variant="contained" color="primary">
                Save
              </Button>
            </Box>
          </CardContent>
        </Card>
      </form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  editSettings(settings) {
    dispatch(editSettingsPending(settings));
  },
});

const mapStateToProps = ({ users }) => ({ me: users.me, settings: users.me.settings });

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
