import { PRINT_ORDER_PENDING, PRINT_ORDER_SUCCESS, PRINT_ORDER_ERROR } from '../actions';

const initialState = { success: null, error: null };

export const printReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case PRINT_ORDER_PENDING:
      return {
        ...state,
        success: false,
      };

    case PRINT_ORDER_SUCCESS:
      return {
        ...state,
        success: payload,
      };

    case PRINT_ORDER_ERROR:
      return {
        ...state,
        error: payload,
      };

    default:
      return state;
  }
};
