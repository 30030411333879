import React, { useState, useEffect, useRef } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import AddButton from '../components/AddButton';
import CategoryItems from '../components/CategoryItems';
import CategoryDialog from '../components/CategoryDialog';
import { fetchCategoriesPending } from '../actions';
import { connect } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { axios } from '../helpers/';

const Categories = ({ getCategories, categories, addCategoryReducer }) => {
  const didMountRef = useRef(false);
  const [state, setState] = useState({ openDialog: false, category: {} });
  useEffect(() => {
    if (didMountRef.current) {
      if (addCategoryReducer.success) {
        getCategories();
        addCategoryReducer.success = null;
      }
    } else {
      getCategories();
      didMountRef.current = true;
    }
  });

  const handleOpenDialog = () => {
    setState({ ...state, openDialog: true });
  };

  const handleCloseDialog = () => {
    setState({ ...state, openDialog: false });
  };

  const editCategory = (category) => (e) => {
    setState({ ...state, category, openDialog: true });
    e.preventDefault();
  };

  const deleteCategory = (id) => async (e) => {
    e.preventDefault();
    try {
      await axios.delete(`api/categories/${id}`);
      getCategories();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Container>
      <Grid container spacing={6}>
        {categories.map((v) => (
          <CategoryItems key={v._id} name={v.categoryName} image={v.image} url={`items/${v._id}`}>
            <div className="category-items__actions">
              <EditIcon onClick={editCategory(v)} className="category-items__actions-edit" />
              <DeleteIcon onClick={deleteCategory(v._id)} className="category-items__actions-delete" />
            </div>
          </CategoryItems>
        ))}
      </Grid>
      <AddButton onClick={handleOpenDialog} />
      <CategoryDialog category={state.category} open={state.openDialog} handleClose={handleCloseDialog} />
    </Container>
  );
};

const mapStateToProps = ({ fetchCategoriesReducer, addCategoryReducer }) => ({
  categories: fetchCategoriesReducer.categories,
  addCategoryReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getCategories() {
    dispatch(fetchCategoriesPending());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
