export default {
  get loggedInState() {
    return !!localStorage.getItem('token');
  },
  setLoggedInState(state) {
    if (state) {
      localStorage.setItem('token', state);
      return;
    }

    localStorage.removeItem('token');
  },
};
