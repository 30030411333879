import { axios } from '../../helpers';
import { call, put, takeLatest } from 'redux-saga/effects';
import { ADD_ITEM_PENDING, addItemSuccess, addItemError } from '../../actions';

export function* addItem() {
  yield takeLatest(ADD_ITEM_PENDING, function* ({ payload }) {
    try {
      const response = yield call(axios.post, 'api/items', payload);
      yield put(addItemSuccess(response.data));
    } catch (e) {
      yield put(addItemError(e.response.data.message));
    }
  });
}
