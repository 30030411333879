import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { userHelper } from '../helpers';

import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import Content from '../Layout/Content';
import SnackBar from '../components/SnackBar';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
  },
});

function Layout({ getMe, ...props }) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      if (props.addCategoryReducer.error) {
        props.addCategoryReducer.error = null;
      }
      if (props.editCategoryReducer.error) {
        props.editCategoryReducer.error = null;
      }
      if (props.addItemReducer.error) {
        props.addItemReducer.error = null;
      }
      if (props.editItemReducer.error) {
        props.editItemReducer.error = null;
      }
      if (props.printReducer.success) {
        props.printReducer.success = null;
      }
      if (props.editSettingsReducer.success) {
        getMe();
        props.editSettingsReducer.success = null;
      }
    } else {
      didMountRef.current = true;
      userHelper.loggedInState && getMe();
    }
  });
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  return (
    <div className={classes.root}>
      <CssBaseline />

      <Header handleDrawerToggle={handleDrawerToggle} history={props.history} />

      <Sidebar handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} />

      <Content />
      {props.editCategoryReducer.error && <SnackBar variant="error" message={props.editCategoryReducer.error} />}
      {props.editCategoryReducer.success && <SnackBar variant="success" message={'Category updated successfully!'} />}
      {props.editItemReducer.error && <SnackBar variant="error" message={props.editItemReducer.error} />}
      {props.editItemReducer.success && <SnackBar variant="success" message={'Item updated successfully!'} />}
      {props.addCategoryReducer.error && <SnackBar variant="error" message={props.addCategoryReducer.error} />}
      {props.addCategoryReducer.success && <SnackBar variant="success" message={'Category added successfully!'} />}
      {props.addItemReducer.error && <SnackBar variant="error" message={props.addItemReducer.error} />}
      {props.addItemReducer.success && <SnackBar variant="success" message={'Item added successfully!'} />}
      {props.printReducer.success && <SnackBar variant="success" message={'Order printed!'} />}
      {props.editSettingsReducer.success && <SnackBar variant="success" message={'Settings updated successfully'} />}
      {props.editSettingsReducer.error && <SnackBar variant="success" message={props.editSettingsReducer.errorMsg} />}
    </div>
  );
}
const mapStateToProps = (props) => {
  const {
    addCategoryReducer,
    editCategoryReducer,
    addItemReducer,
    editItemReducer,
    printReducer,
    editSettingsReducer,
  } = props;

  return {
    addCategoryReducer,
    editCategoryReducer,
    addItemReducer,
    editItemReducer,
    printReducer,
    editSettingsReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMe() {
    dispatch({ type: 'GET_ME' });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
