import { FETCH_ADDITIVES_PENDING, FETCH_ADDITIVES_SUCCESS, FETCH_ADDITIVES_ERROR } from '../../actions';

const initialState = {
  additives: [],
  success: null,
  error: false,
};

export const fetchAdditivesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ADDITIVES_PENDING:
      return {
        ...state,
        success: false,
      };

    case FETCH_ADDITIVES_SUCCESS:
      return {
        ...state,
        additives: payload,
        success: true,
      };

    case FETCH_ADDITIVES_ERROR:
      return {
        ...state,
        success: false,
        error: true,
      };
    default:
      return state;
  }
};
