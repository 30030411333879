import React, { useState, useEffect, useRef } from 'react';
import { axios } from '../helpers';
import { fetchItemsPending } from '../actions';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import AddButton from '../components/AddButton';
import ItemDialog from '../components/itemDialog';
import CategoryItems from '../components/CategoryItems';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const Items = (props) => {
  const didMountRef = useRef(false);
  const {
    addItemReducer,
    items,
    getItems,
    match: {
      params: { categoryId },
    },
  } = props;
  const [state, setState] = useState({
    items: [],
    item: {},
    categories: [],
    tab: 0,
    openDialog: false,
  });

  useEffect(() => {
    if (didMountRef.current) {
      if (addItemReducer.success) {
        getItems(categoryId);
        addItemReducer.success = null;
      }
    } else {
      getItems(categoryId);
      didMountRef.current = true;
    }
  });

  const handleOpenDialog = () => {
    setState({ ...state, openDialog: true });
  };

  const handleCloseDialog = () => {
    setState({ ...state, openDialog: false });
  };

  const editItem = (item) => (e) => {
    setState({ ...state, item, openDialog: true });
    e.preventDefault();
  };

  const deleteItem = (id) => async (e) => {
    e.preventDefault();
    try {
      await axios.delete(`api/items/${id}`);
      getItems();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Grid container spacing={2}>
      {items.map((v) => (
        <CategoryItems key={v._id} type="item" name={v.itemName} url={`/items/${v._id}`} {...v}>
          <div className="category-items__actions">
            <EditIcon onClick={editItem(v)} className="category-items__actions-edit" />
            <DeleteIcon onClick={deleteItem(v._id)} className="category-items__actions-delete" />
          </div>
        </CategoryItems>
      ))}
      <AddButton onClick={handleOpenDialog} />
      <ItemDialog categoryId={categoryId} open={state.openDialog} item={state.item} handleClose={handleCloseDialog} />
    </Grid>
  );
};

const mapStateToProps = ({ fetchItemsReducer, addItemReducer }) => ({
  items: fetchItemsReducer.items,
  addItemReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getItems(categoryId) {
    dispatch(fetchItemsPending(categoryId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Items);
