export const ADD_ITEM_PENDING = 'ADD_ITEM_PENDING';
export const ADD_ITEM_SUCCESS = 'ADD_ITEM_SUCCESS';
export const ADD_ITEM_ERROR = 'ADD_ITEM_ERROR';

export function addItemPending(item) {
  return {
    type: ADD_ITEM_PENDING,
    payload: item,
  };
}

export function addItemSuccess(response) {
  return {
    type: ADD_ITEM_SUCCESS,
    payload: response,
  };
}

export function addItemError(error) {
  return {
    type: ADD_ITEM_ERROR,
    payload: error,
  };
}
