import { axios } from '../../helpers';
import { call, put, takeLatest } from 'redux-saga/effects';
import { EDIT_ADDITIVE_PENDING, editAdditiveSuccess, editAdditiveError } from '../../actions';

export function* editAdditive() {
  yield takeLatest(EDIT_ADDITIVE_PENDING, function* ({ payload }) {
    try {
      const response = yield call(axios.put, `api/additives/${payload.additiveId}`, payload.additiveModel);
      yield put(editAdditiveSuccess(response.data));
    } catch (e) {
      yield put(editAdditiveError(e.response.data.message));
    }
  });
}
