import { EDIT_ITEM_PENDING, EDIT_ITEM_SUCCESS, EDIT_ITEM_ERROR } from '../../actions';

const initialState = {
  success: null,
  error: null,
};

export const editItemReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case EDIT_ITEM_PENDING:
      return {
        ...state,
        success: false,
        error: null,
      };

    case EDIT_ITEM_SUCCESS:
      return {
        ...state,
        success: true,
        error: null,
      };

    case EDIT_ITEM_ERROR:
      return {
        ...state,
        success: false,
        error: payload,
      };
    default:
      return state;
  }
};
