import { axios } from '../../helpers';
import { call, put, takeLatest } from 'redux-saga/effects';
import { EDIT_SETTINGS_PENDING, editSettingsSuccess, editSettingsError } from '../../actions';

export function* editSettingsSaga() {
  yield takeLatest(EDIT_SETTINGS_PENDING, function* ({ payload }) {
    try {
      const response = yield call(axios.put, `/api/users/${payload.restaurant}/settings`, payload);
      yield put(editSettingsSuccess(response.data));
    } catch (e) {
      yield put(editSettingsError(e.response.data.message));
    }
  });
}
