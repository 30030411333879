export const FETCH_ADDITIVES_PENDING = 'FETCH_ADDITIVES_PENDING';
export const FETCH_ADDITIVES_SUCCESS = 'FETCH_ADDITIVES_SUCCESS';
export const FETCH_ADDITIVES_ERROR = 'FETCH_ADDITIVES_ERROR';

export function fetchAdditivesPending() {
  return {
    type: FETCH_ADDITIVES_PENDING,
  };
}

export function fetchAdditivesSuccess(additive) {
  return {
    type: FETCH_ADDITIVES_SUCCESS,
    payload: additive,
  };
}

export function fetchAdditivesError(error) {
  return {
    type: FETCH_ADDITIVES_ERROR,
    error: error,
  };
}
