import { all } from 'redux-saga/effects';
import { fetchMe } from './users';
import { fetchCategories, addCategory, editCategory } from './categories';
import { fetchItems, addItem, editItem } from './items';
import { fetchAdditives, addAdditive, editAdditive } from './additives';
import { fetchSettingsSaga, editSettingsSaga } from './settings';
import { printOrder } from './print';

export default function* () {
  yield all([
    fetchMe(),

    fetchItems(),
    addItem(),
    editItem(),

    fetchCategories(),
    addCategory(),
    editCategory(),

    fetchAdditives(),
    addAdditive(),
    editAdditive(),
    printOrder(),
    fetchSettingsSaga(),
    editSettingsSaga(),
  ]);
}
