import { axios } from '../helpers';
import { call, put, takeLatest } from 'redux-saga/effects';
import { PRINT_ORDER_PENDING, printOrderSuccess, printOrderError } from '../actions';

export function* printOrder() {
  yield takeLatest(PRINT_ORDER_PENDING, function* ({ payload }) {
    try {
      const response = yield call(axios.post, `/screen/print/${payload}`);
      yield put(printOrderSuccess(response.data));
    } catch (e) {
      yield put(printOrderError(e.response.data.message));
    }
  });
}
